.mobileCard {
  font-size: 30px;
  font-style: normal;
}
.content {
  padding: 0 5px !important;
  font-size: 14px;
  .blur {
    filter: blur(8px);
  }
  .subscription {
    display: flex;
    padding: 0 5px 20px 5px;
    flex-direction: column;
    position: relative;
    .offlineText {
      position: absolute;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      p {
        margin-top: -40px;
        font-weight: 600;
        display: flex;
      }
    }
    .progress {
      display: flex;
      position: relative;
      padding: 0;
      flex: 1;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .used {
        font-size: 14px;
        color: #f75369;
        font-weight: 600;
      }
      .usedDesc {
        font-size: 10px;
        color: #eee;
      }
    }
    .progressDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      .till {
        display: flex;
        font-size: 10px;
        color: #666;
      }
      .left {
        display: flex;
        font-size: 16px;
        font-weight: 600
      }
    }
  }
  .sep {
    margin: -5px;
    background-color: #eee;
    height: 1px;
  }
  .mobileDetails {
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      flex-direction: row;
      padding: 12px 10px;
    }
    .valueContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      .label {
        font-size: 10px;
        font-weight: 600;
        color: #999;
        .capitalize {
          text-transform: capitalize;
        }
      }
      .value {
        font-size: 16px;
        font-weight: 500;
        color: #eee;
        text-align: left;
      }
      .trim {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .colored {
        color: #f75369;
      }
    }
    .valueRight {
      align-items: flex-end;
    }
  }
}
